<template>
  <v-container fluid class="pt-0">
    <BidStream v-if="!$vuetify.breakpoint.mdAndUp"></BidStream>
    <v-row class="pb-2" dense>
      <v-col cols="12" :md="bidStream ? 8 : 12">
        <v-carousel
          :show-arrows="false"
          :cycle="true"
          continuous
          :height="carouselHeight()"
        >
          <v-carousel-item
            v-for="(item,i) in carouselItems"
            :key="i"
            :src="$vuetify.breakpoint.xsOnly ? item.xsSrc : item.src"
            :contain="item.contain"
            @click.stop="carouselClick(item)"
            :class="{ clickable: !!item.ad }"
            :position="item.position || 'center'"
            :style="getBgColor(item)"
            >
            <div class="bg-overlay" v-if="item.headline">
              <v-row class="fill-height d-flex align-center justify-center">
              <v-col class="text-center textShadow">
                <p class="text-h3">{{ item.headline }}</p>
                <p class="text-h5">{{ item.subheadline }}</p>
                <p class=""></p>
                <v-btn
                    rounded
                    small
                    color="color3 color3Text--text"
                    @click.stop="carouselClick(item)"
                    v-if="item.action"
                  >
                    {{item.action.text}}
                  </v-btn>
              </v-col>
            </v-row>
            </div>
          </v-carousel-item>
          <!-- <v-carousel-item
            v-for="(item,i) in carouselItems"
            :key="i"
            :src="item.src"
            :contain="item.contain"
            @click.stop="carouselClick(item)"
            :class="{ clickable: !!item.ad }"
            :position="item.ad ? item.ad.position : 'center'"
          >
            <v-row class="fill-height" justify="center" align="center" style="padding-bottom: 50px" v-if="item.action && item.action.to === '/claim-my-profile'" >
              <v-overlay absolute color="white">
                <v-col class="text-center" cols="12" >
                  <v-btn
                    class="py-9 px-6"
                    color="color3Text color3--text"
                    :to="item.action.to">{{item.action.text}}
                  </v-btn>
                </v-col>
              </v-overlay>
            </v-row>
            <v-row class="fill-height" align="end" style="padding-bottom: 50px" v-else-if="item.action" >
              <v-col class="text-right" cols="12" >
                <v-btn color="color3Text color3--text" :to="item.action.to">{{item.action.text}}</v-btn>
              </v-col>
            </v-row>
          </v-carousel-item> -->
        </v-carousel>
      </v-col>
      <v-col cols="12" :md="bidStream ? 4 : 12" v-if="$vuetify.breakpoint.mdAndUp">
        <BidStream lg="true"></BidStream>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col class="my-1" v-if="theme.props.includes('multiTournamentTeamSearch')">
        <multi-tournament-team-search :username="theme.username">
          <template v-slot:activator="{ on }">
            <v-btn
              block
              rounded
              color="color3 color3Text--text"
              large
              class="pa-6"
              v-on="on"
            >
              <v-icon class="mr-4">
                fas fa-list-ol
              </v-icon>
              Find your team
            </v-btn>
          </template>
        </multi-tournament-team-search>
      </v-col>
      <v-col class="my-1" v-if="!theme.props.includes('multiTournamentTeamSearch')">
        <v-btn block rounded color="color3 color3Text--text" large class="pa-6" :to="{name: 'filtered-tournaments', params: {filter: 'upcoming'}}">
          <v-icon class="mr-4">
            far fa-calendar-alt
          </v-icon>
          Events
        </v-btn>
      </v-col>
      <v-col class="my-1" v-if="!excludes.includes('rankings')">
        <v-btn block rounded color="color3 color3Text--text" large class="pa-6" :to="rankingRoute">
          <v-icon class="mr-4">
            fas fa-list-ol
          </v-icon>
          Rankings
        </v-btn>
      </v-col>
      <v-col class="my-1" v-if="!excludes.includes('points') && theme.username !== 'avp'">
        <v-btn block rounded color="color3 color3Text--text" large class="pa-6" :to="points">
          <v-icon class="mr-4">
            fas fa-tally
          </v-icon>
          Point Systems
        </v-btn>
      </v-col>
      <v-col class="my-1" v-if="theme.username === 'avp'">
        <v-btn block rounded color="color3 color3Text--text" large class="pa-6" @click.stop="popAvp">
          <v-icon class="mr-4">
            fas fa-tally
          </v-icon>
          2024 Points
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import isDark from '@/classes/IsDark'
import debounce from 'lodash.debounce'
const MultiTournamentTeamSearch = () => import('@/components/Team/MultiTournamentTeamSearch')

export default {
  data: () => ({
    carouselItems: [
      // { src: 'https://vblimages.blob.core.windows.net/images/e7577c69-55e1-47c2-86d6-4400a17abb7e.jpg', headline: '', subheadline: '', actionText: '', actionPath: 'http://www.sideout.com/', target: '_blank', ad: true, interval: 5000, contain: true },
      // { src: 'https://vblimages.blob.core.windows.net/images/d276c2f2-a8ae-4b29-a608-f459e34b47c1.jpg', headline: 'Upload Your Player Photo!', subheadline: 'Keep your profile up to date', actionText: 'Upload now...', actionPath: '/claim-my-profile', target: '', ad: false, interval: 5000, contain: true },
      // { src: 'https://vblimages.blob.core.windows.net/images/stare3.jpg', headline: 'Player Rankings', subheadline: 'Nationwide Point System', actionText: 'Find out how it works...', actionPath: '/', target: '', ad: false, interval: 5000, contain: true },
      // { src: 'https://vblimages.blob.core.windows.net/images/usc1.jpg', headline: 'Featured Player', subheadline: 'Meet Luke Skywalker', actionText: 'Learn more...', actionPath: '/', target: '', ad: false, interval: 5000, contain: true },
      // { src: 'https://vblimages.blob.core.windows.net/images/ucla1.jpg', headline: 'Indoor Club - Coming Soon!', subheadline: 'Team registration and events', actionText: 'Learn more...', actionPath: '/', target: '', ad: false, interval: 5000, contain: true },
      // { src: 'https://vblimages.blob.core.windows.net/images/sunset-volleyball.jpg', headline: 'Find Your Next Tournament', subheadline: 'Search, sign up, and play', actionText: 'Find your next win...', actionPath: '/', target: '', ad: false, interval: 5000, contain: true }
    ]
  }),
  computed: {
    ...mapGetters([
      'theme',
      'rankings',
      'liveUpdates',
      'bidStream'
    ]),
    welcomeColor () {
      return isDark(this.theme.color2) ? 'color2--text' : 'color2Text--text'
    },
    weclomeText () {
      return this.theme.welcomeText || 'Welcome to Volleyball Life'
    },
    a () {
      return this.theme.props
    },
    rankingRoute () {
      if (this.rankings.length) {
        const orgRanks = this.rankings.filter(f => !f.sb)
        if (orgRanks.length === 1 || this.rankings.length === 1) {
          const r = orgRanks[0] || this.rankings[0]
          return r.route
        }
      }
      return { name: 'rankings-home' }
    },
    points () {
      return this.theme.username === 'avp' ? { name: 'avppoints' } : this.theme.username === 'aau' ? { name: 'aaupoints' } : { name: 'points-aau-tab' }
    },
    xsClass () {
      return {
        'display-2': this.$vuetify.breakpoint.xs,
        'display-4': !this.$vuetify.breakpoint.xs,
        'color2--text': isDark(this.theme.color2),
        'color2Text--text': !isDark(this.theme.color2)
      }
    },
    welcomeClassString () {
      const color = isDark(this.theme.color2)
        ? 'color2--text'
        : isDark(this.theme.color2Text) ? 'color2Text--text'
          : isDark(this.theme.color1) ? 'color1--text' : 'color1Text--text'
      const size = this.$vuetify.breakpoint.xs ? 'display-2' : 'display-4'
      return `${color} ${size}`
    },
    excludes () {
      return this.theme.excludes ? this.theme.excludes.split(',') : []
    }
  },
  methods: {
    popAvp () {
      window.open('https://vblimages.blob.core.windows.net/files/1e068947-8e00-453d-975e-cdf5c546a1d8.pdf', '_blank')
    },
    getBgColor (item) {
      return item.color ? `background-color: ${item.color};` : null
    },
    load () {
      this.$VBL.theme.getCarousel(this.theme.username)
        .then(r => {
          this.carouselItems = r.data.map(m => {
            if (m.actionText || m.actionPath) {
              if (m.actionText.startsWith('adClick')) {
                const adSplit = m.actionPath.split('~')
                m.ad = {
                  id: +adSplit[0],
                  actionUrl: adSplit[1],
                  relative: adSplit[1].startsWith('/'),
                  position: 'left center'
                }
                m.action = {
                  text: m.actionText.replace('adClick', '')
                }
              } else {
                m.action = {
                  to: m.actionPath,
                  text: m.actionText
                }
              }
            }
            return m
          })
        })
        .catch(e => console.log(e.response))
    },
    carouselClick (item) {
      if (item.ad) {
        this.actionClick(item.ad)
        return
      }
      if (item.action && item.action.to.startsWith('http')) {
        this.carouselLink(item.action.to, (item.target || '_blank'))
        return
      }
      this.$router.push(item.action.to)
    },
    actionClick: debounce(function (ad) {
      this.doActionClick(ad)
    }, 333),
    doActionClick (ad) {
      const dto = {
        id: ad.id,
        page: this.$route.fullPath
      }
      this.liveUpdates.invoke('AdClick', dto)
      ad.relative ? this.$router.push(ad.actionUrl) : window.open(ad.actionUrl, '_blank')
    },
    carouselHeight () {
      if (window.innerWidth < 480) {
        return '400px'
      } else {
        return '550px'
      }
    },
    carouselLink (path, target) {
      if (!target) {
        return this.carouselButton(path)
      } else {
        window.open(path, target)
      }
    }
  },
  components: {
    MultiTournamentTeamSearch
  },
  created () {
    const a = this.theme.altHome
    if (this.theme.altHome2) {
      this.$router.push(JSON.parse(this.theme.altHome2))
    } else if (a) {
      var to = a.startsWith('/') ? a : { name: a }
      this.$router.push(to)
    }
    this.load()
  }
}
// Cap Lock: http://jsfiddle.net/Mottie/a6nhqvv0/
</script>

<style>
  .greybacked {
    background-color: rgba(0,0,0,.5);
  }
  .noClick {
    pointer-events: none;
  }
  .textShadow {
    text-shadow: 0 2px 4px rgba(0,0,0,0.95);
  }
  .bg-overlay{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    background-repeat:no-repeat;
    background-size: cover;
}
</style>
